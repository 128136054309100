/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const SimpleLightbox = require('simple-lightbox');

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.startsWith('/work')) {
    new SimpleLightbox({ elements: '.work-residence-images a:not(.video)' });
  }

  let header = document.getElementById('header');
  let footer = document.getElementById('footer');
  if (location.pathname === '/') {
    header.style.backgroundColor = 'transparent';
    footer.style.display = 'none';
  } else {
    header.style.backgroundColor = '#d9d9d9';
    footer.style.display = 'block';
  }
};
